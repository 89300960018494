import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Script from "react-load-script"
import { navigate } from "gatsby"
import ArrowSubmit from "./images/arrow-submit"
import { verifyZipCode } from "../global/firebase"
import LoadingSpinner from "./loading-spinner"
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"

export default function AddressInput({
  id,
  onAddressComplete,
  shouldNavigate = true,
  showIcon = false,
  showLoader = false,
}) {
  let autocomplete
  const [address, setAddress] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  //useEffect(() => {
    const handleOnLoad = () => {
        if (typeof window !== "undefined") {

          if (window.google) {



            autocomplete = new window.google.maps.places.Autocomplete(
              document.getElementById(id),
              {
                types: ["geocode"],
                //types: ['geocode', 'premise'],
              }
            )
            autocomplete.setFields(["address_components", "formatted_address"])
            
            autocomplete.addListener("place_changed", handlePlaceChange) 

            
           
          }

        }
    }
  //});

  const checkZipCode = async zipCode => {
    setIsLoading(true)
    try {
      const res = await verifyZipCode({ zip_code: zipCode })
      setIsLoading(false)
      return !!res.data.success
    } catch (e) {
      console.warn(e)
      setIsLoading(false)
    }
  }

  const handlePlaceChange = async () => {

    if (/\d/.test(document.getElementById(id).value) == true) {

      const { address_components, formatted_address } = autocomplete.getPlace()

      if (!(address_components && formatted_address)) {
        return
      }

      saveAddressToLocalStorage(formatted_address)

      setAddress(formatted_address)
      const addressValues = parseGoogleAddressComponents(address_components)
      const isValid = await checkZipCode(addressValues.postal_code)

      if (shouldNavigate) {
        navigate("/design-builder", {
          state: {
            formatted_address,
            isValid,
            ...addressValues,
          },
        })
      } else {
        return (
          onAddressComplete &&
          onAddressComplete({ formatted_address, isValid, ...addressValues })
        )
      }
    }
    
  }

  const parseGoogleAddressComponents = arr => {

    const address = {}

    arr.forEach(add => {
      address[add.types[0]] = add.long_name
    })

    return address
  }

  function saveAddressToLocalStorage(formatted_address){

    //var uuid = require("uuid");
    //var request_id = uuid.v4();

    //SAVE ADDRESS AND REQUEST_ID TO LOCAL STORAGE
    //sessionStorage.setItem("request_id", request_id); // set a variable
    sessionStorage.setItem("formatted_address", formatted_address); // set a variable
  }

  //GET BUILDER DATA FROM DB
  function getBuilderData(product_type, data_type){
    
      var data = new FormData();
      data.append("product_type", product_type);
      data.append("data_type", data_type);
      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.checksolarcost.com/v1/getBuilderData");
      xhr.send(data);
      xhr.onreadystatechange = function() {
      if (xhr.readyState === XMLHttpRequest.DONE) {
          //READ DATA
          var response = xhr.responseText;
          sessionStorage.setItem(product_type + "_" + data_type, response);
        } 
      }
  }

  //CHECK IF ADDRESS IS FULL
  const isAddressValid = () => {
    if (/\d/.test(document.getElementById(id).value) == false) {
      setAddress("");
      document.getElementById(id).placeholder = "Please enter full address";
    }
  };

  useEffect(() => {getBuilderData("00", "product") },[]);
  useEffect(() => {getBuilderData("01", "product") },[]);
  useEffect(() => {getBuilderData("02", "product") },[]);

  useEffect(() => {getBuilderData("00", "swatches") },[]);
  useEffect(() => {getBuilderData("01", "swatches") },[]);
  useEffect(() => {getBuilderData("02", "swatches") },[]);

  useEffect(() => {getBuilderData("00", "cost") },[]);
  useEffect(() => {getBuilderData("01", "cost") },[]);
  useEffect(() => {getBuilderData("02", "cost") },[]);

  return (
    <>

    <form
      autoComplete="off"
      className="w-full flex justify-center mt-8 tracking-wide"
    >
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAEUA8nRhSd10_ezuWEMEV5TwELbbuwxf4&libraries=places"
        onLoad={handleOnLoad}
      />
      <input
        autoComplete="false"
        name="hidden"
        type="text"
        className="hidden"
      />

      <label htmlFor="address" className="hidden">
        Address
      </label>

      <div
        className="relative w-full"
        style={{
          minWidth: "300px",
          maxWidth: "500px",
        }}
      >
        <input
          id={id}
          name="address"
          type="text"
          autoComplete="off"
          placeholder="Enter instalation address"
          className="w-full max-w-lg py-2 bg-transparent border-b border-white text-white outline-none placeholder-white text-xl font-light tracking-wide"
          onChange={e => { setAddress(e.target.value); isAddressValid(); } }
          value={address}
        />



        {showIcon ? (
          <div
            className="absolute right-0 top-0 ml-20"
            style={{
              transform: "translate(0%, 50%)",
            }}
          >
            {showLoader && isLoading ? (
              <LoadingSpinner></LoadingSpinner>
            ) : (
              <ArrowSubmit fill="white"></ArrowSubmit>
            )}
          </div>
        ) : null}
      </div>
    </form>
    </>
  )
}

AddressInput.propTypes = {
  id: PropTypes.string.isRequired,
  onAddressComplete: PropTypes.func,
}
