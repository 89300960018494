import React from "react"

export default function ArrowScrollHome({}) {
  return (
    <div className="flex flex-col justify-center text-white fill-current items-center mb-12">
      <svg
        className="w-6 h-6 mx-4 hidden md:hidden lg:block"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 35.75 22.68"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <polygon
              className="cls-1"
              points="20.21 16.35 20.21 22.68 35.75 6.51 35.75 0 20.21 16.35"
            />
            <polygon
              className="cls-1"
              points="0 6.51 15.54 22.68 15.54 16.41 0 0 0 6.51"
            />
          </g>
        </g>
      </svg>
      <small className="hidden md:hidden lg:block">Scroll</small>
    </div>
  )
}
