import React from "react"

export default function ModalMenuIcon({}) {
  return (
    <div className="">
      <svg
        className="fill-current h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        width="27.999"
        height="15.35"
        viewBox="0 0 27.999 15.35"
      >
        <path d="M0,0V2.893H28V0Z" transform="translate(0 12.458)" />
        <path d="M0,0V2.893H28V0Z" transform="translate(0 0)" />
      </svg>
    </div>
  )
}
