import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

//import { Type, Field } from "../../../state"

import Script from "react-load-script"
import { navigate } from "gatsby"
import ArrowSubmit from "./images/arrow-submit"
import { verifyZipCode } from "../global/firebase"
import LoadingSpinner from "./loading-spinner"

import { Link } from "gatsby"

import './slider.css'; //https://www.cssportal.com/style-input-range/ Amazing source


//CLEAN LOCAL STORAGE ON STARTUP
if (typeof window !== 'undefined') {
  sessionStorage.clear(); }

export default function AddressInput({
  id,
  onAddressComplete,
  shouldNavigate = true,
  showIcon = false,
  showLoader = false,
  disabled_status,
}) {
  let autocomplete
  const [address, setAddress] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  
    const handleOnLoad = () => {
      //useEffect(() => {   
      if (window.google) {
        autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById(id),
          {
            types: ["geocode"],
          }
        )

        autocomplete.setFields(["address_components", "formatted_address"])
        autocomplete.addListener("place_changed", handlePlaceChange)
      }
    //});
    }
  

  const checkZipCode = async zipCode => {
    setIsLoading(true)
    try {
      const res = await verifyZipCode({ zip_code: zipCode })
      setIsLoading(false)
      return !!res.data.success
    } catch (e) {
      console.warn(e)
      setIsLoading(false)
    }
   }

  const handlePlaceChange = async () => {
    const { address_components, formatted_address } = autocomplete.getPlace()

    if (!(address_components && formatted_address)) {
      return
    }

    checkFormStatus(formatted_address)

    saveAddressToLocalStorage(formatted_address)

    setAddress(formatted_address)
    const addressValues = parseGoogleAddressComponents(address_components)
    const isValid = await checkZipCode(addressValues.postal_code)

    //if (shouldNavigate) {
    //  navigate("/design-builder", {
    //    state: {
    //      formatted_address,
    //      isValid,
    //      ...addressValues,
    //    },
    //  })
    //} else {
      return (
        onAddressComplete &&
        onAddressComplete({ formatted_address, isValid, ...addressValues })
      )
  }
  
  const parseGoogleAddressComponents = arr => {
    const address = {}

    arr.forEach(add => {
      address[add.types[0]] = add.long_name
    })

    return address
  }

    function checkFormStatus(address) {
    
      var gray = "w-full max-w-lg py-2 bg-transparent border-b border-gray-500 text-gray-500 outline-none placeholder-gray-500 text-xl font-light tracking-wide";
      var white = "w-full max-w-lg py-2 bg-transparent border-b border-white text-white outline-none placeholder-white text-xl font-light tracking-wide";

      //var property_value = document.getElementById('property-value').value;
      //var mortgage_balance = document.getElementById('mortgage-balance').value;
      //var income_level = document.getElementById('income-level').value;
      //var credit_score = document.getElementById('credit-score').value;

      //if (property_value != "") {
      //  document.getElementById("mortgage-balance").className = white;
      //  document.getElementById("mortgage-balance").disabled = false;
      //  document.getElementById("property-value-title").innerHTML = '<small>Your Current Property Value</small>';
      //}

      //if (mortgage_balance != "") {
      //  document.getElementById("income-level").className = white;
      //  document.getElementById("income-level").disabled = false;
      //  document.getElementById("mortgage-balance-title").innerHTML = '<small>Your Current Mortgage Balance</small>';
      //}

      //if (income_level != "") {
      //  document.getElementById("credit-score").className = white;
      //  document.getElementById("credit-score").disabled = false;
      //  document.getElementById("income-level-title").innerHTML = '<small>Your Current Household Income</small>';
      //}

      //if (credit_score != "") { 
        //document.getElementById("calculator-address").className = white;
        //document.getElementById("calculator-address").disabled = false;
      //  document.getElementById("credit-score-title").innerHTML = '<small>Your Credit Score</small>';
      //}

      if (address != '' && typeof address == 'string') {

          document.getElementById("spinner-form").hidden = false;

          //SAVE DATA TO LOCAL STORAGE
          sessionStorage.setItem("property_value", sessionStorage.getItem('property-value-selector')); // set a variable
          sessionStorage.setItem("mortgage_balance", sessionStorage.getItem('mortgage-balance-selector')); // set a variable
          sessionStorage.setItem("income_level", sessionStorage.getItem('household-income-selector')); // set a variable
          sessionStorage.setItem("credit_score", sessionStorage.getItem('credit-score-selector')); // set a variable

          //sessionStorage.setItem("property_value", property_value); // set a variable
          //sessionStorage.setItem("mortgage_balance", mortgage_balance); // set a variable
          //sessionStorage.setItem("income_level", income_level); // set a variable
          //sessionStorage.setItem("credit_score", credit_score); // set a variable

      }
    }

    function saveAddressToLocalStorage(formatted_address){

      //var request_id = s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);

      var uuid = require("uuid");
      var request_id = uuid.v4();

      //SAVE ADDRESS AND REQUEST_ID TO LOCAL STORAGE
      sessionStorage.setItem("request_id", request_id); // set a variable
      sessionStorage.setItem("formatted_address", formatted_address); // set a variable

      //calculatePayment();
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      //formatter.format(max)
    });
  
    function setDefaultValues(){

      if (sessionStorage.getItem('property-value-selector') == null) {
        sessionStorage.setItem('property-value-selector', 500000); }
      
      if (sessionStorage.getItem('mortgage-balance-selector') == null) {
        sessionStorage.setItem('mortgage-balance-selector', 250000); }

      if (sessionStorage.getItem('household-income-selector') == null) {
        sessionStorage.setItem('household-income-selector', 75000); }
      
      if (sessionStorage.getItem('credit-score-selector') == null) {
        sessionStorage.setItem('credit-score-selector', 700); }

    }

    setDefaultValues()

    function sliderRange(parameter, min, max){

        document.getElementById(parameter + "-input").min=min;
        document.getElementById(parameter + "-input").max=max;

        var elem = document.getElementById(parameter + "-input");
        var rangeValue = function(){

            var newValue = elem.value;
            if (newValue == max) { 

              if (parameter == "credit-score") {

                document.getElementById(parameter + "-selector").innerHTML = max + ' or higher'
                sessionStorage.setItem(parameter + "-selector", newValue);

                } else {
                document.getElementById(parameter + "-selector").innerHTML = formatter.format(max) + ' or more'
                sessionStorage.setItem(parameter + "-selector", newValue); }
            }

            else if ( newValue == min ) {

              if (parameter == "credit-score") {

                document.getElementById(parameter + "-selector").innerHTML = min + ' or lower'
                sessionStorage.setItem(parameter + "-selector", newValue);

                } else {
                document.getElementById(parameter + "-selector").innerHTML = formatter.format(min) + ' or less' 
                sessionStorage.setItem(parameter + "-selector", newValue); }
            }

            else {

              if (parameter == "credit-score") {

                document.getElementById(parameter + "-selector").innerHTML = newValue;
                sessionStorage.setItem(parameter + "-selector", newValue);

                } else {
                document.getElementById(parameter + "-selector").innerHTML = formatter.format(newValue);
                sessionStorage.setItem(parameter + "-selector", newValue); }
            }
        }
        elem.addEventListener("input", rangeValue);

      }

  return (
 
      <m id="full-page-content">

      <form autoComplete="off" className="w-full flex justify-center mt-8 tracking-wide">

        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAEUA8nRhSd10_ezuWEMEV5TwELbbuwxf4&libraries=places"
          onLoad={handleOnLoad}
        />

        <input
          autoComplete="false"
          name="hidden"
          type="text"
          className="hidden"
        />

        <label htmlFor="address" className="hidden">
          Address
        </label>

        <div className="relative w-full" style={{minWidth: "100%", maxWidth: "100%",}}>

        <br></br>

        <div class="w-full max-w-lg py-2 text-gray-500 text-xl font-light text-left tracking-wide">
          Your Current Property Value
        </div>
        <div class="w-full max-w-lg py-2 text-white-500 text-xl font-light text-left tracking-wide" 
        id="property-value-selector">
          {formatter.format(sessionStorage.getItem('property-value-selector'))}
        </div>
        <div class="slider">
          <input class="slider"
            id="property-value-input"
            type="range"
            defaultValue={sessionStorage.getItem('property-value-selector')}
            step="10000"
            min="50000"
            max="1500000"
            onChange={() => sliderRange("property-value", "50000", "1500000")} //parameter, min, max
            />
        </div>
       
        <br></br>

        <div class="w-full max-w-lg py-2 text-gray-500 text-xl font-light text-left tracking-wide">
          Your Current Mortgage Balance
        </div>
        <div class="w-full max-w-lg py-2 text-white-500 text-xl font-light text-left tracking-wide" 
        id="mortgage-balance-selector">
          {formatter.format(sessionStorage.getItem('mortgage-balance-selector'))}
        </div>

        <div class="slider">
          <input class="slider"
            id="mortgage-balance-input"
            type="range"
            defaultValue={sessionStorage.getItem('mortgage-balance-selector')}
            step="10000"
            min="50000"
            max="1500000"
            onChange={() => sliderRange("mortgage-balance", "50000", "1500000")} //parameter, min, max
            />
        </div>
       
        <br></br>

        <div class="w-full max-w-lg py-2 text-gray-500 text-xl font-light text-left tracking-wide">
        Your Current Household Income
        </div>
        <div class="w-full max-w-lg py-2 text-white-500 text-xl font-light text-left tracking-wide" 
        id="household-income-selector">
          {formatter.format(sessionStorage.getItem('household-income-selector'))}
        </div>

        <div class="slider">
          <input class="slider"
            id="household-income-input"
            type="range"
            defaultValue={sessionStorage.getItem('household-income-selector')}
            step="1000"
            min="50000"
            max="300000"
            onChange={() => sliderRange("household-income", "50000", "300000")} //parameter, min, max
            />
        </div>

        <br></br>

        <div class="w-full max-w-lg py-2 text-gray-500 text-xl font-light text-left tracking-wide">
          Your Credit Score
        </div>
        <div class="w-full max-w-lg py-2 text-white-500 text-xl font-light text-left tracking-wide" 
        id="credit-score-selector">
          {sessionStorage.getItem('credit-score-selector')}
        </div>

        <div class="slider">
          <input class="slider"
            id="credit-score-input"
            type="range"
            defaultValue={sessionStorage.getItem('credit-score-selector')}
            step="1"
            min="579"
            max="800"
            onChange={() => sliderRange("credit-score", "579", "800")} //parameter, min, max
            />
        </div>

        {/* <select 
          name="property-value" 
          id="property-value" 
          className="w-full max-w-lg py-2 bg-transparent border-b border-white text-white outline-none placeholder-white text-xl font-light tracking-wide"
          onChange={checkFormStatus}
          >
          <option value="" disabled selected>Your Current Property Value</option>
          <option value="1">$49,999 or lower</option>
          <option value="2">$50,000 - $99,999</option>
          <option value="3">$100,000 - $199,999</option>
          <option value="4">$200,000 - $299,999</option>
          <option value="5">$300,000 - $399,999</option>
          <option value="6">$400,000 - $499,999</option>
          <option value="7">$500,000 - $599,999</option>
          <option value="8">$600,000 - $699,999</option>
          <option value="9">$700,000 - $799,999</option>
          <option value="10">$800,000 - $899,999</option>
          <option value="11">$900,000 - $999,999</option>
          <option value="12">$1,000,000 - $1,099,999</option>
          <option value="13">$1,100,000 - $1,199,999</option>
          <option value="14">$1,200,000 - $1,299,999</option>
          <option value="15">$1,300,000 - $1,399,999</option>
          <option value="16">$1,400,000 - $1,499,999</option>
          <option value="17">$1,500,000 or higher</option>
          </select>

          <div className="font-light text-gray-500 text-left">
            <m id="property-value-title"><br></br></m>                            
          </div>
         
          <select 
          name="mortgage-balance" 
          id="mortgage-balance" 
          className="w-full max-w-lg py-2 bg-transparent border-b border-gray-500 text-gray-500 outline-none placeholder-white text-xl font-light tracking-wide"
          onChange={checkFormStatus}
          disabled
          >
          <option value="" disabled selected>Your Current Mortgage Balance</option>
          <option value="1">$49,999 or lower</option>
          <option value="2">$50,000 - $99,999</option>
          <option value="3">$100,000 - $199,999</option>
          <option value="4">$200,000 - $299,999</option>
          <option value="5">$300,000 - $399,999</option>
          <option value="6">$400,000 - $499,999</option>
          <option value="7">$500,000 - $599,999</option>
          <option value="8">$600,000 - $699,999</option>
          <option value="9">$700,000 - $799,999</option>
          <option value="10">$800,000 - $899,999</option>
          <option value="11">$900,000 - $999,999</option>
          <option value="12">$1,000,000 - $1,099,999</option>
          <option value="13">$1,100,000 - $1,199,999</option>
          <option value="14">$1,200,000 - $1,299,999</option>
          <option value="15">$1,300,000 - $1,399,999</option>
          <option value="16">$1,400,000 - $1,499,999</option>
          <option value="17">$1,500,000 or higher</option>
          </select>

          <div className="font-light text-gray-500 text-left">
            <m id="mortgage-balance-title"><br></br></m>                            
          </div>

          <select 
          name="income-level" 
          id="income-level" 
          className="w-full max-w-lg py-2 bg-transparent border-b border-gray-500 text-gray-500 outline-none placeholder-white text-xl font-light tracking-wide" 
          onChange={checkFormStatus}
          disabled
          >
          <option value="" disabled selected>Your Current Household Income</option>
          <option value="1">$49,999 or lower</option>
          <option value="2">$50,000 - $99,999</option>
          <option value="3">$100,000 - $149,999</option>
          <option value="4">$150,000 - $199,999</option>
          <option value="5">$200,000 - $249,999</option>
          <option value="6">$250,000 - $299,999</option>
          <option value="7">$300,000 - $349,999</option>
          <option value="8">$350,000 - $399,999</option>
          <option value="9">$400,000 - $449,999</option>
          <option value="10">$450,000 - $499,999</option>
          <option value="11">$500,000 or higher</option>
          </select>

          <div className="font-light text-gray-500 text-left">
            <m id="income-level-title"><br></br></m>                            
          </div> 

          <br></br><br></br>

          <select
          name="credit-score" 
          id="credit-score" 
          className="w-full max-w-lg py-2 bg-transparent border-b border-gray-500 text-gray-500 outline-none placeholder-white text-xl font-light tracking-wide" 
          onChange={checkFormStatus}
          disabled
          >
          <option value="" disabled selected>Your Credit Score</option>
          <option value="1">Excellent (800+ FICO® Score)</option>
          <option value="2">Very Good (770-799 FICO® Score)</option>
          <option value="3">Very Good (770-799 FICO® Score)</option>
          <option value="4">Good (700-739 FICO® Score)</option>
          <option value="5">Good (670-699 FICO® Score)</option>
          <option value="6">Fair (640-669 FICO® Score)</option>
          <option value="7">Fair (620-639 FICO® Score)</option>
          <option value="8">Fair (600-619 FICO® Score)</option>
          <option value="9">Fair (580-599 FICO® Score)</option>
          <option value="10">Challenged (&lt; 579 FICO® Score)</option>
          </select>

          <div className="font-light text-gray-500 text-left">
            <m id="credit-score-title"><br></br></m>                            
          </div>*/} 

          <br></br>
          <br></br>

          <input
            id={id}
            name="address"
            type="text"
            autoComplete="off"
            placeholder="Address Of Installation"
            className="w-full max-w-lg py-2 bg-transparent border-b border-white text-white outline-none placeholder-white text-xl font-light tracking-wide"
            //onChange={e => setAddress(e.target.value)}
            onChange={e => { setAddress(e.target.value) }}
            value={address}
          />
          
          <br></br>
          <br></br>

          <div id="spinner-form" className="relative w-full text-center overflow-hidden h-auto" hidden>
              <LoadingSpinner />
          </div>

        </div>

      </form>

      </m>

  )


}

//AddressInput.propTypes = {
//  id: PropTypes.string.isRequired,
//  onAddressComplete: PropTypes.func, }