import React, { createRef, useState, useEffect } from "react"
import { Link } from "gatsby"

import ArrowSubmit from "../images/arrow-submit"
import "./call-to-action-qualify.css"

export default function CallToActionQualify({
  text = "see if you qualify",
  to = "/design-builder",
  fill = "",
  className = "",
}) {
  const [shouldAnimate, setShouldAnimate] = useState(false)
  useEffect(() => {
    if (shouldAnimate) {
      divRef.current.classList.add("slide-animate")
    } else {
      divRef.current.classList.remove("slide-animate")
    }
  }, [shouldAnimate])

  const linkRef = createRef()
  const divRef = createRef()

  useEffect(() => {
    linkRef.current.addEventListener("mouseenter", onMouseEnter)
    linkRef.current.addEventListener("mouseleave", onMouseLeave)

    return () => {
      linkRef.current.removeEventListener("mouseenter", onMouseEnter)
      linkRef.current.removeEventListener("mouseleave", onMouseLeave)
    }
  }, [])

  const onMouseEnter = () => {
    setShouldAnimate(true)
  }

  const onMouseLeave = () => {
    setShouldAnimate(false)
  }

  return (
    <Link
      ref={linkRef}
      to={to}
      className={`flex items-center capitalize ${className}`}
    >
      <p className="font-light tracking-wide arrow-button">{text}</p>
      <div
        ref={divRef}
        className="fill-current text-white ml-4"
        style={{ fill }}
      >
        {" "}
        <ArrowSubmit></ArrowSubmit>
      </div>
    </Link>
  )
}
