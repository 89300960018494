import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useLayoutEffect, useRef, useEffect } from "react"
import Logo from "../images/logo"
import TargetIcon from "../images/target-icon"
import CallToActionQualify from "../call-to-action-qualify/call-to-action-qualify"
import ModalMenuIcon from "../images/modal-menu-icon"
import "./header.css"


export default function MenuItems() {

  const [isExpanded, toggleExpansion] = useState(false)

return (
      <>
      
        <div className="items-center flex-shrink-0 text-white mr-10 hidden md:flex">
          <Link to="/" className="z-10">
            <Logo className="w-26" fill="white"></Logo>
          </Link>
        </div>
        
        <div className="flex w-full flex-row justify-between md:hidden items-center">
          <div className="flex w-1/2 ml-6 text-gray-700">
          <Link to="/" className="z-10">
            <Logo className="w-26" fill="white"></Logo>
          </Link>
          </div>
          <div className="flex w-1/2 justify-end">
            <button
              onClick={() => toggleExpansion(!isExpanded)}
              className="flex items-center px-5 py-2 text-white hover:text-gray-200 focus:outline-none"
            >
              <ModalMenuIcon
                className={`${isExpanded ? `hidden` : `block`} `}
              ></ModalMenuIcon>
              <div
                className={`${isExpanded ? `block` : `hidden`} rotate-icon `}
              >
                <svg
                  className="w-6 h-6 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <rect
                        className="cls-1"
                        y="16.14"
                        width="16.14"
                        height="3.72"
                      />
                      <rect
                        className="cls-1"
                        x="19.86"
                        y="16.14"
                        width="16.14"
                        height="3.72"
                      />
                      <rect
                        className="cls-1"
                        x="16.14"
                        y="19.86"
                        width="3.72"
                        height="16.14"
                      />
                      <rect
                        className="cls-1"
                        x="16.14"
                        width="3.72"
                        height="16.14"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </button>
          </div>
        </div>
        
        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full block flex-grow md:flex flex md:items-center md:w-auto font-light tracking-wide `}
        >
          
          <div className="capitalize pt-40 pb-56 md:pt-0 md:pb-0 text-xl md:text-xl mobile-nav">
           {/* 
            <Link
              activeClassName="nav-active"
              to="/"
              href="#responsive-header"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none block md:hidden"
            >
              Home
            </Link>
            <Link
              activeClassName="nav-active"
              to="/how-it-works"
              href="#responsive-header"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none"
            >
              How it works
            </Link>
            <Link
              activeClassName="nav-active"
              to="/products"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none"
            >
              Products
            </Link>
            <Link
              activeClassName="nav-active"
              to="/faqs"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none"
            >
              FAQ
            </Link>
            <Link
              activeClassName="nav-active"
              to="/about"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none"
            >
              About
            </Link>
            <Link
              activeClassName="nav-active"
              //to="/blog"
              to="/blog"
              target="blank"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none"
            >
              Blog
            </Link> 
            <Link
              activeClassName="nav-active"
              to="/developers"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none"
            >
              Developers
            </Link>
          
            
            <Link
              activeClassName="nav-active"
              target="_blank"
              to="https://portal.checksolarcost.com/modal-dashboard.php"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none"
            >
              Developers
            </Link>
            
           
            <Link
              activeClassName="nav-active"
              to="/faqs"
              className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-b border-gray-500 md:border-none"
            >
              FAQ
            </Link>*/}
          </div>
         
          {/*<div className="items-center hidden justify-end text-white font-light tracking-wide lg:block">
            <CallToActionQualify></CallToActionQualify>
          </div>*/}
        </div>
        
      </>
    )

}