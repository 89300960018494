import React, { createRef, useState, useEffect } from "react"
import SEO from "../../components/seo"
import FeasibilityStepper from "../../components/feasibility/feasibility-stepper"
import LayoutHeader from "../../components/layout2"
import LoadingSpinner from "../../components/loading-spinner"
import { Link } from "gatsby"
import TargetIcon from "../../components/images/target-icon"
import { usePlacesWidget } from "react-google-autocomplete";

import Autocomplete from "react-google-autocomplete";
//import SEO from "../../components/seo"

//http://localhost:8000/design-builder/?default_config=0&is_get_started=true

export default function getStarted() {

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}

    useEffect(() => {
      addWebEvent("visit", "get-started")
    },[]);


//REDERICT PAGE TO CONFIGURATOR
if (typeof window !== 'undefined') {
  window.location = '/design-builder/?default_config=0&is_get_started=true';
}


    return (
      <>
      </>
    )
  }